import Slide from "./components/Slide";
import "./app.css";
import prismaImg from "./assets/prisma_darker.png";

export const App = () => {
  return (
    <div className="page_container">
      <div className="grid_container">
        <div className="item_container hero_item">
          <h1 className="title main_title">
            Thomas Flemming
            <br />
            <span style={{ fontSize: "1.5rem", fontWeight: "lighter" }}>
              Utvikling, design og prosjektledelse
            </span>
          </h1>
        </div>

        <Slide className="item_container item_image thomas_img">&nbsp;</Slide>
        <Slide
          className="item_container item_wide text_item text_bold light_box"
          delay={0.1}
        >
          <h3>Full stack utvikling</h3>
          <span className="paragraph">
            Med over 30 år med utvikling og utforming av nettsteder, så
            reduseres risikoen for å ta feil teknologiske valg. Jeg har altid
            likt å lære nye ting. Etter hvert har jeg endt opp som en ekte all
            rounder som gjør alt av frontend, backend, oppsett av skytjenester,
            UX, UI, opplæring og ledelse. Er født og bosatt i Oslo.
          </span>
        </Slide>

        {/* brown_gradient  */}
        <Slide
          className="item_container item_wide text_item text_bold box2"
          delay={0.3}
        >
          <h3>Frontend</h3>
          <span className="paragraph">
            React og TypeScript har vært det foretrukne frontendrammeverket de
            siste 10 årene. Leverer også Angular. Har kodet alt fra ren
            JavaScript siden 1996 og jQuery til dagens frontendrammeverk. Har
            fulgt med på utviklingen av CSS siden begynnelsen i nittiårene.
            <br />
            <br />
          </span>
        </Slide>

        <Slide
          className="item_container item_image prisma_img"
          delay={0.4}
        >
          <figure className="effect-julia">
            <figcaption>
              <div>
                <p className="paragraph">UX og utvikling</p>
              </div>
            </figcaption>
          </figure>
        </Slide>

        {/* <Slide
          className="item_container item_image prisma_img hover_text"
          delay={0.4}
        >
          <div className="hover_textbox">
            <div className="textbox">NorQuant, CTO & UX</div>
          </div>
        </Slide> */}

        <Slide className="item_container item_image nav_img">
        <figure className="effect-julia">
            <figcaption>
              <div>
                <p className="paragraph">Frontend & Universell utforming</p>
              </div>
            </figcaption>
          </figure>
          {/* <div className="hover_textbox">
            <div className="textbox">NAV, frontend</div>
          </div> */}
        </Slide>
        <Slide className="item_container item_wide text_item text_bold orange_green_gradient">
          <h3>Kontakt meg</h3>
          <span className="paragraph">
            Ta gjerne kontakt for å høre om jeg er ledig eller for en prat:
            <br />
            <br />
            E-post:{" "}
            <a href="mailto:thomas.flemming@gmail.com">
              thomas.flemming@gmail.com
            </a>
            <br />
            Mobil: <a href="tel:+4791852967">+47 918 52 967</a>
            <br />
            <a
              title="instagram"
              href="httphttps://www.instagram.com/hoffandersen/"
            >
              <div className="some_image instagram_logo" />
            </a>
            <a
              title="linked_in"
              href="https://www.linkedin.com/in/kristian-hoff-andersen-140b4a42/"
            >
              <div className="some_image linkedin_logo" />
            </a>
            <a title="facebook" href="https://www.facebook.com/hoffandersen">
              <div className="some_image facebook_logo" />
            </a>
          </span>
        </Slide>

        <Slide className="item_container item_taller text_item text_bold light_box">
          <h3>Backend utvikling</h3>
          <span className="paragraph">
            De første 10 programmerings- språkene er de vanskeligste. Jeg
            skriver gjerne backendkode i Python, Kotlin, Node, Ruby og til dels
            Java. Foretrekker å jobbe med PostgreSQL. Kan sette opp hosting på
            AWS og GCP. Gjerne med automatisert bygg, test og deploy.
          </span>
        </Slide>

        <Slide
          className="item_container item_taller text_item text_bold brown_gradient"
          delay={0.1}
        >
          <h3>Universell utforming</h3>
          <span className="paragraph">
            Universell utforming handler om mye mer enn å lage nettsteder for
            blinde og svaksynte, og eller å følge krav om WCAG-standard. Som
            konsulent hos NAV lærte vi at brukere kan ha mange andre
            utfordringer også.
          </span>
        </Slide>

        <Slide
          className="item_container item_taller text_item text_bold light_box"
          delay={0.2}
        >
          <h3>Utdanning</h3>
          <span className="paragraph">
            Master i informatikk fra UiO. Mastergrad i språkteknologi.
            <br />
            <br />
            Utviklet chattebot for spørringen i dynamisk database med
            boligannonser.
            <br />
            <br />
            Kunsthistorie studier fra UiO.
          </span>
        </Slide>

        <Slide className="item_container text_item item_wide dark_gradient">
          <i>
            &quot; Det er sjelden jeg ber noen om hjelp til å skrive CSS kode.
            Når jeg først ber noen om hjelp, så foretrekker jeg å spørre Thomas
            Flemming. Han imponerer med sin dype forståelse for CSS som språk,
            og kunnskapen om de siste utvidelsene av språket. &quot;
          </i>
          <br />
          <br />- Håkon Wium Lie, CSS skaper
        </Slide>

        <Slide className="item_container item_image church_sunset_img">
          &nbsp;
        </Slide>

        <Slide className="item_container text_item brown_gradient">
          <h3>Roller</h3>
          <span className="paragraph">
            CTO hos NorQuant. Selvstendig utvikler for kunder som NAV, Nordea,
            Telia og Oslo Børs VPS. Seniorkonsulent hos Mogul og andre.
            Overingeniør hos USIT ved UiO. Leder av utvikleravdelingen hos EUnet
            Media.
          </span>
        </Slide>

        <Slide className="item_container text_item item_wide light_box">
          <h3>Bransjeerfaring</h3>
          <span className="paragraph">
            Har laget løsninger på nett blant annet for bank, retail, shipping,
            telecom og media. Det mest utfordrende har vært å utforme
            brukergrensesnitt til et verktøy for teknisk analyse av aksjefond. I
            oppstartsbedriften NorQuant brukte jeg mye av tiden som CTO på å
            snakke med kunder. For mange er det tung materie å forstå metodene
            til for eksempel Fama og French for å identifisere et fonds
            investeringsstrategier.
          </span>
        </Slide>

        {/* <Slide className="item_container item_image oslo1_img" delay={0.1}>
          &nbsp;
        </Slide> */}
        {/* <Slide className="item_container item_image oslo2_img" delay={0.2}>
          &nbsp;
        </Slide>
        <Slide className="item_container item_image oslo3_img" delay={0.3}>
          &nbsp;
        </Slide> */}
      </div>

      <div className="copyright paragraph">
        © Copyright 2025, Thomas Flemming. All rights reserved. Privacy
        Statement (EU), Cookie Policy (EU), Terms and Conditions apply.
        <br />
        Design by Thomas Flemming
      </div>
    </div>
  );
};
